<template>
  <v-card class="elevation-1" tile>
    <v-card-text>
      <validation-observer slim v-slot="{ handleSubmit }">
        <v-row>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$t(`language.${$helpers.get(employee, 'language', 'TURKISH')}`)"
              :label="$t('hr.employee.language_role.language')"
              rules="required"
              :index="0"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <language-picker
                  v-model="employeePayload.language"
                  outlined
                  dense
                  style="max-width: 240px"
                  color="tertiary"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'role.name')"
              :label="$t('hr.employee.language_role.role')"
              rules="required"
              :index="1"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="false"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <role-picker
                  v-model="employeePayload.role"
                  outlined
                  dense
                  style="max-width: 340px"
                  color="tertiary"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
        </v-row>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
  import { EMPLOYEE_LANGUAGE_ROLE } from "../query";

  export default {
    name: "EmployeeLanguageRole",
    props: {
      profileSettings: Object
    },
    components: {
      LanguagePicker: () => import("@/components/selectpickers/LanguagePicker"),
      RolePicker: () => import("@/components/selectpickers/RolePicker"),
      ProfileField: () => import("./components/ProfileField")
    },
    data: () => ({
      loading: false,
      employeePayload: {
        language: null,
        role: null
      },
      employee: null,
      activeIndex: -1,
      saving: false
    }),
    computed: {
      isEditable() {
        return this.$helpers.get(this.profileSettings, this.$enums.PROFILE_SETTINGS.LANGUAGE_AND_ROLE);
      }
    },
    methods: {
      onSaveClick() {
        this.saving = true;
        this.$api.employeeService
          .updateProfileCredentials(this.$store.state.auth.user.id, this.employeePayload)
          .then(response => {
            if (response.status === 200) {
              this.activeIndex = -1;
              this.fetchEmployeeLanguageRole();
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => (this.saving = false));
      },
      onCancelClick() {
        this.activeIndex = -1;
        this.setEmployeePayload(this.employee);
      },
      onEditClick(index) {
        this.activeIndex = index;
      },
      setEmployeePayload(employee) {
        this.employeePayload = { ...employee };
        this.employeePayload.role = this.$helpers.get(employee, "role.id");
      },
      async fetchEmployeeLanguageRole() {
        this.loading = true;
        this.$apollo
          .query({
            query: EMPLOYEE_LANGUAGE_ROLE,
            variables: {
              id: this.$store.state.auth.user.id
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.employee = data.employee.profile;
              this.setEmployeePayload(data.employee.profile);
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => (this.loading = false));
      }
    },
    created() {
      this.fetchEmployeeLanguageRole();
    }
  };
</script>
